import "./index.css";
import { PrimaryButton } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../sections/Sidebar";
export const CampaignCell = ({ name }: any) => {
  const navigate = useNavigate();

  return (
    <div className="campaign-cell">
      <span className="bold" style={{ fontWeight: 700 }}>
        {name}
      </span>
      <span style={{ marginTop: 5 }}> 12 surveys</span>
      <div
        style={{ marginTop: 20 }}
        onClick={() => {
          navigate("/campaign/report");
        }}
      >
        <PrimaryButton text={"Open"} />
      </div>
    </div>
  );
};

export const Campaigns = () => {
  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        <div className="teaser">
          <div className="teaser-name">
            {" "}
            <div>
              <svg
                className="teaser-icon"
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25 0C11.215 0 0 11.215 0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.785 0 25 0ZM32.5 32.5L12.5 37.5L17.5 17.5L37.5 12.5L32.5 32.5Z"
                  fill="black"
                />
                <path
                  d="M25 30C27.7614 30 30 27.7614 30 25C30 22.2386 27.7614 20 25 20C22.2386 20 20 22.2386 20 25C20 27.7614 22.2386 30 25 30Z"
                  fill="black"
                />
              </svg>
            </div>
            ROWO Engage
          </div>
          <div className="teaser-description">
            Engagement surveys, regular reports, deep dive analytics, valuable
            insights and strategy development
          </div>
          <div className="teaser-coming_soon">COMING SOON</div>
        </div>
        {/*<span style={{ fontSize: 18, fontWeight: 600 }}>&lt; Campaigns</span>
        <div style={{ marginTop: 20 }} className="campaigns">
          <CampaignCell name={"Engagement"} />
          <CampaignCell name={"Satisfaction"} />
          <CampaignCell name={"Development"} />
  </div>*/}
      </div>
    </div>
  );
};
