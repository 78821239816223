import "./index.css";

const data = {
  "1": 20,
  "2": 100,
  "3": 47,
  "4": 13,
  "5": 0,
  "6": 0,
  "7": 11,
  "8": 27,
  "9": 31,
  "10": 55,
};

export const ScaleChart = ({ value }: { value: number }) => {
  let sum = 0;
  for (const property in data) {
    sum += data[property as keyof typeof data];
  }
  console.log(sum);

  const GridItem = ({ i, count }: { i: number; count: string }) => {
    let size = 0;
    let color = "#fff";
    if ((100 / sum) * i > 50) {
      size = 110;
    } else if ((100 / sum) * i > 40) {
      size = 90;
    } else if ((100 / sum) * i > 30) {
      size = 70;
    } else if ((100 / sum) * i > 20) {
      size = 60;
    } else if ((100 / sum) * i > 10) {
      size = 45;
    } else if ((100 / sum) * i > 5) {
      size = 35;
    } else if ((100 / sum) * i > 5) {
      size = 30;
    } else if ((100 / sum) * i > 0) {
      size = 25;
    } else if ((100 / sum) * i === 0) {
      size = 0;
    }

    if (count === "1" || count === "2" || count === "3") {
      color = "#EE4E4E";
    } else if (count === "10" || count === "9") {
      color = "#14814D";
    } else if (count === "4" || count === "5" || count === "6") {
      color = "#F9BB5E";
    } else if (count === "8") {
      color = "#73C856";
    } else if (count === "7") {
      color = "#BACE41";
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: color,
            borderRadius: 100,
            height: size,
            width: size,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: i > 0 ? "#fff" : "#999999",
          }}
        >
          {count}
        </div>
      </div>
    );
  };
  const rendergrid = () => {
    const elements = [];
    for (const property in data) {
      elements.push(
        <GridItem i={data[property as keyof typeof data]} count={property} />
      );
    }
    return elements;
  };

  const renderAnnotation = () => {
    const elements = [];
    for (const property in data) {
      elements.push(
        <div>
          {" "}
          {((100 / sum) * data[property as keyof typeof data]).toFixed(0)}%
        </div>
      );
    }
    return elements;
  };
  return (
    <div>
      <div
        style={{
          display: "grid",
          gridAutoColumns: "minmax(50px, 1fr)",

          gridAutoFlow: "column",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        {rendergrid()}
      </div>
      <div
        style={{
          display: "grid",
          gridAutoColumns: "minmax(50px, auto)",
          gridAutoRows: 60,
          gridAutoFlow: "column",
          justifyItems: "center",
          alignItems: "center",
          fontSize: 14,
          color: "#696969",
        }}
      >
        {renderAnnotation()}
      </div>
    </div>
  );
};

/*
   <div
        style={{
          background: "#EE4E4E",
          borderRadius: 100,
          height: 30,
          width: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        1
      </div>

      <div>2</div>
      <div>3</div>
      <div>4</div>
      <div>5</div>
      <div>6</div>
      <div>7</div>
      <div>8</div>
      <div>9</div>
      <div>10</div>





       <div
          style={{
            color: "#000",
            marginTop: 10,
            position: "relative",
            top: 40,
          }}
        >
          {((100 / sum) * i).toFixed(0)}%
        </div>
*/
