import { HalfPieChart, CircleChart, ScaleChart } from "./components";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../../utils";
import { Sidebar } from "../Sidebar";
export const Report = () => {
  const navigate = useNavigate();
  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        <span style={{ fontSize: 18, fontWeight: 600 }}>
          Engagement Campaign 01 Report
        </span>
        <Tabs
          data={{
            Report: "/campaign/report",
            Data: "/campaign/data",
            Employees: "/campaign/employees",
          }}
          active="Report"
        />

        <div style={{ display: "flex", marginTop: 20 }}>
          <div
            style={{
              height: 290,
              width: 240,
              background: "#F8F9FB",
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <span
              className="fadeInUp-animation"
              style={{ fontWeight: 500, fontSize: 16, marginTop: 30 }}
            >
              Engagement Score
            </span>

            <div>
              <div style={{ width: 200, height: 100 }}>
                <HalfPieChart value={84} />
              </div>
              <div
                className="stats-item-value fadeInUp-animation"
                style={{
                  width: 200,
                  textAlign: "center",
                  marginTop: -33,
                  fontSize: 35,
                  fontWeight: 700,
                }}
              >
                84
              </div>
            </div>
            <span
              style={{
                fontSize: 14,
                textAlign: "center",
                color: "#868686",
                width: "80%",
                marginTop: 30,
              }}
            >
              Your engagement score counted as an average of other categories
            </span>
          </div>
          <div className="grid-container">
            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={13} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  13
                </div>
                Compensation
              </div>
            </div>
            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={62} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  62
                </div>
                <span style={{ minWidth: 200 }}>Work Environment</span>
              </div>
            </div>
            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={92} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  92
                </div>
                Well-being
              </div>
            </div>
            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={75} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  75
                </div>
                Culture
              </div>
            </div>

            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={92} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  92
                </div>
                Growth Opportunities
              </div>
            </div>

            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={87} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  87
                </div>
                Team Collaboration
              </div>
            </div>

            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={83} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  83
                </div>
                Communication
              </div>
            </div>
            <div className="grid-item">
              <div style={{ position: "relative", height: 115, width: 115 }}>
                <CircleChart value={74} />
                <div className="circle-chart-value fadeInUpCircle-animation">
                  74
                </div>
                Job Satisfaction
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <span style={{ fontWeight: 500, fontSize: 16 }}>
            Essential Insights
          </span>
          <ul style={{ width: 700, fontWeight: 500 }}>
            <li>
              Your <span className="bold">Well being</span> score improved the
              most since the last time. Keep it up! 💪
            </li>
            <li>
              Opinions polarized on the question{" "}
              <span className="bold">
                “How would you rate your work environment?”
              </span>{" "}
              53% of respondents picked score 8-10, while 39% picked score 1-4.
            </li>
            <li>
              Only 21% answered Yes to the question{" "}
              <span className="bold">
                “Do you see opportunities to build career in this company?”
              </span>
            </li>
            <li>
              <span className="bold">“Money”</span> was the top answer on the
              multiple selection question{" "}
              <span className="bold">
                “What is your motivation to work here?”
              </span>
              . 94% picked it.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
