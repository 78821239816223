import { useState, useEffect } from "react";
import "./index.css";
import { SingleChoice, Scale, OpenQuestion } from "../Survey/components";

import { SEND_WHISPER_MESSAGE } from "../../lib";

import { useQuery, useMutation } from "@apollo/client";

import { GET_WHISPER_BY_LINK } from "../../lib";

import {
  getWhisperByLink as getWhisperByLinkData,
  getWhisperByLinkVariables,
} from "../../lib/graphql/query/Whisper/__generated__/getWhisperByLink";

import {
  sendWhisperMessage as sendWhisperMessageData,
  sendWhisperMessageVariables,
} from "../../lib/graphql/query/Whisper/__generated__/sendWhisperMessage";

import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

const Message = ({
  responses,
  sendResponse,
  finalMessage,
  setFinalMessage,
  setCurrentQuestion,
}: any) => {
  const [selectedOption, setSelectedOption] = useState(null);
  let { id: link } = useParams();

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#fff");
  }, []);
  const SendedMessage = () => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "#fff",
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 30,
          boxSizing: "border-box",
        }}
      >
        <div
          className="fadein-up fadeInUp-animation-survey"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ margin: "auto", marginBottom: 20 }}>
            <svg
              width="60"
              height="60"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25 0C11.215 0 0 11.215 0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.785 0 25 0ZM32.5 32.5L12.5 37.5L17.5 17.5L37.5 12.5L32.5 32.5Z"
                fill="black"
              />
              <path
                d="M25 30C27.7614 30 30 27.7614 30 25C30 22.2386 27.7614 20 25 20C22.2386 20 20 22.2386 20 25C20 27.7614 22.2386 30 25 30Z"
                fill="black"
              />
            </svg>
          </div>
          <div
            style={{
              margin: "auto",
              marginTop: 0,
              marginBottom: 0,
              fontSize: 18,
              fontWeight: 500,
            }}
          >
            Thank you!
          </div>
          <div
            style={{
              margin: "auto",
              marginTop: 20,
              width: 220,
              textAlign: "center",
            }}
          >
            Your message has been successfully sent.
          </div>
          <div
            style={{
              marginTop: "auto",
              marginBottom: 10,
              width: "100%",
              display: "flex",
            }}
          >
            <button
              onClick={() => {
                setCurrentQuestion(-1);
                setFinalMessage(false);
              }}
              style={{
                background: "#171145",
                color: "#fff",
                padding: "20px 20px",
                boxSizing: "border-box",
                textAlign: "center",
                cursor: "pointer",
                width: "100%",
              }}
            >
              Back to main
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className="fadein-up fadeInUp-animation-survey"
      style={{
        padding: 30,
        position: "relative",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        background: "none",
      }}
    >
      <div
        style={{
          marginTop: 20,
          fontSize: 18,
          lineHeight: 1.5,
          marginBottom: 30,
          textAlign: "center",
        }}
      >
        What's on your mind?
        <div style={{ color: "#A7A7A7", fontSize: 16, marginTop: 10 }}>
          <span style={{ marginRight: 5 }}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9562 3.44012C11.9461 3.3393 11.9101 3.24266 11.8517 3.15924C11.7933 3.07582 11.7144 3.00836 11.6223 2.96317L6.74736 0.563423C6.6627 0.521715 6.56934 0.5 6.47467 0.5C6.38 0.5 6.28664 0.521715 6.20198 0.563423L1.32708 2.96317C1.23523 3.00862 1.15653 3.07616 1.09826 3.15954C1.03999 3.24292 1.00405 3.33943 0.993757 3.44012C0.987054 3.50432 0.408159 9.89965 6.22757 12.4476C6.30533 12.4821 6.38967 12.5 6.47498 12.5C6.56029 12.5 6.64462 12.4821 6.72238 12.4476C12.5418 9.89965 11.9629 3.50492 11.9562 3.44012ZM6.47498 11.2375C2.3502 9.27451 2.1552 5.08515 2.18872 3.88108L6.47498 1.7711L10.7582 3.87988C10.7807 5.07315 10.5583 9.29011 6.47498 11.2375Z"
                fill="#7E7E7E"
                fill-opacity="0.66"
              />
              <path
                d="M5.8656 6.85138L4.46833 5.47572L3.60669 6.32403L5.8656 8.548L9.34323 5.12416L8.48159 4.27585L5.8656 6.85138Z"
                fill="#7E7E7E"
                fill-opacity="0.66"
              />
            </svg>
          </span>
          It's anonymous
        </div>
      </div>

      <OpenQuestion
        setSelectedOption={setSelectedOption}
        className="fadein-up fadeInUp-animation-survey"
      />

      <div
        style={{
          marginTop: "auto",
          marginBottom: 10,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => {
            console.log(selectedOption);
            sendResponse({
              variables: {
                whisperId: link,
                message: selectedOption,
              },
            });
          }}
          style={{
            background: "#171145",
            color: "#fff",

            padding: "20px 20px",
            boxSizing: "border-box",
            textAlign: "center",
            cursor: "pointer",
            width: "100%",
          }}
        >
          Send
        </button>
      </div>
      {finalMessage ? <SendedMessage /> : null}

      {/*} <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "#A1A1A1",
            fontSize: 18,
            fontWeight: 500,
            marginLeft: 40,
            cursor: "pointer",
          }}
        >
          Skip
        </div>

        <div
          style={{
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: 20,
            opacity: selectedOption !== null ? 1 : 0.5,
            cursor: "pointer",
          }}
          onClick={() => {
            if (selectedOption) {
              responses.push({ selectedOption });
              console.log(responses);
            }
          }}
        >
          <img src="/assets/next-question.svg" style={{ height: 80 }} />
        </div>
      </div> */}
    </div>
  );
};

const StartScreen = ({ name, surveyLength, startSurvey, data }: any) => {
  const { displayedName, displayedPosition } = data.getWhisper;
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#fff");
  }, []);
  return (
    <div
      className="frame"
      style={{
        /*background: "linear-gradient(0deg, #7869E7 20%, #FFFFFF 100%)",*/
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 30,
        boxSizing: "border-box",
      }}
    >
      <img
        src="/assets/underlying.svg"
        style={{ height: 182, marginTop: -20 }}
      />
      <img
        src="/assets/logo-whisper.svg"
        height={50}
        className="logospin"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: 85,
        }}
      />
      <div style={{ margin: "auto", textAlign: "center", fontSize: 24 }}>
        <div style={{ fontSize: 21 }}>Direct Line to</div>
        <div style={{ fontWeight: 600, marginTop: 5 }}>
          {displayedName} {/*displayedPosition*/}
          {/*<div style={{ display: "inline-block" }}>(CEO of something two)</div>*/}
        </div>
      </div>
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          fontSize: 16,
          marginBottom: "auto",
          padding: "0 10px",
        }}
      >
        <div>
          A place to share thoughts, concerns and suggestions. Anonymously.
        </div>
      </div>

      {/* <div style={{ width: "100%", height: "50px" }}>
          <img
            src="/assets/Campaigns-icon.svg"
            style={{ marginRight: 10 }}
            height={30}
          />
        </div>
        } <div style={{ textAlign: "left", fontWeight: 600, fontSize: 30 }}>
          {name}
        </div>
        <div
          className=""
          style={{
            textAlign: "left",
            fontSize: 16,
            display: "flex",
            flexDirection: "column",
            gap: 15,
            marginTop: 20,
          }}
        >
          <div>
            <img src="/assets/survey-questions.svg" className="survey-icon" />
            {surveyLength} questions
          </div>
          <div>
            <img src="/assets/survey-time.svg" className="survey-icon" />7 mins
          </div>
          <div>
            <img src="/assets/survey-user.svg" className="survey-icon" />
            Anonymous
          </div>

          







      </div>*/}

      <div
        style={{
          marginTop: "auto",
          marginBottom: 30,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => startSurvey()}
          style={{
            background: "#171145",
            color: "#fff",
            padding: "20px 80px",
            cursor: "pointer",
          }}
        >
          Continue
        </button>
      </div>
      <div
        style={{
          marginTop: 5,
          marginBottom: 5,
          width: "100%",
          display: "flex",
          fontSize: 15,
          justifyContent: "center",
        }}
      >
        <span style={{ fontWeight: 700 }}>ROWO</span>&nbsp;Whisper
      </div>
    </div>
  );
};

const FinalScreen = ({ startAgain }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      Дякую, Survey completed!
      <button
        onClick={startAgain}
        style={{
          background: "#000",
          color: "#fff",
          padding: "20px 80px",
          cursor: "pointer",
        }}
      >
        Почати знову
      </button>
    </div>
  );
};

const Question = ({
  question,
  nextQuestion,
  surveyLength,
  currentQuestion,
  responses,
}: any) => {
  const { _id: id, name, type, options } = question;
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#fff");
  }, []);
  return (
    <div
      key={id}
      className="fadein-up fadeInUp-animation-survey"
      style={{
        padding: 30,
        position: "relative",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        background: "none",
      }}
    >
      <div style={{ marginTop: 20, fontWeight: 700, fontSize: 14 }}>
        {currentQuestion + 1}/{surveyLength}
      </div>

      <div
        style={{
          marginTop: 20,
          fontSize: 18,
          lineHeight: 1.5,
          marginBottom: 20,
        }}
      >
        Write whatever you want
      </div>
      {type === "scale" ? (
        <Scale
          setSelectedOption={setSelectedOption}
          className="fadein-up fadeInUp-animation-survey"
        />
      ) : null}

      {type === "open" ? (
        <OpenQuestion
          setSelectedOption={setSelectedOption}
          className="fadein-up fadeInUp-animation-survey"
        />
      ) : null}

      {type === "single" ? (
        <SingleChoice
          setSelectedOption={setSelectedOption}
          options={options}
          question={name}
          className="fadein-up fadeInUp-animation-survey"
        />
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {type === "open" ? (
          <div
            style={{
              color: "#A1A1A1",
              fontSize: 18,
              fontWeight: 500,
              marginLeft: 40,
              cursor: "pointer",
            }}
          >
            Skip
          </div>
        ) : null}

        <div
          style={{
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: 20,
            opacity: selectedOption !== null ? 1 : 0.5,
            cursor: "pointer",
          }}
          onClick={() => {
            if (selectedOption) {
              responses.push({ selectedOption });
              nextQuestion();
              console.log(responses);
            }
          }}
        >
          <img src="/assets/next-question.svg" style={{ height: 80 }} />
        </div>
      </div>
    </div>
  );
};

const QuestionOperator = ({
  data,
  responses,
  sendResponse,
  finalMessage,
  setFinalMessage,
}: any) => {
  const [currentQuestion, setCurrentQuestion] = useState(-1);

  /*useEffect(() => {
    if (currentQuestion === surveyLength) {
      return sendResponse();
    }
  }, [currentQuestion]);*/

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };
  const startAgain = () => {
    setCurrentQuestion(-1);
  };
  return (
    <div className="survey-display">
      {
        currentQuestion === -1 ? (
          <StartScreen data={data} startSurvey={nextQuestion} />
        ) : (
          <Message
            sendResponse={sendResponse}
            finalMessage={finalMessage}
            setFinalMessage={setFinalMessage}
            setCurrentQuestion={setCurrentQuestion}
          />
        )

        // Відображення питань та варіантів відповідей
      }
    </div>
  );
};

export const Whisper = () => {
  let { id: link } = useParams();
  const [finalMessage, setFinalMessage] = useState(false);

  const { data, loading, error } = useQuery<
    getWhisperByLinkData,
    getWhisperByLinkVariables
  >(GET_WHISPER_BY_LINK, {
    variables: { whisperLink: link! },
    onError: () => {
      console.log("😐 Oh... Reload the page and try again");
    },
    onCompleted: (data) => {},
  });
  console.log("rerender");

  const showSurvey = () => {
    return data ? (
      <QuestionOperator
        data={data}
        sendResponse={sendResponse}
        finalMessage={finalMessage}
        setFinalMessage={setFinalMessage}
      />
    ) : null;
  };

  const [sendResponse] = useMutation<
    sendWhisperMessageData,
    sendWhisperMessageVariables
  >(SEND_WHISPER_MESSAGE, {
    onCompleted: (data) => {
      setFinalMessage(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  /*const sendResponses = () => {
    sendResponse({
      variables: {
        whisperId: "65fb4e8a96bf9b1f4167fd99",
        message: responses,
      },
    });
  };*/

  return (
    <div
      style={{
        background: "#4537AC" /*#717A80*/,
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showSurvey()}
      {/* 
      <div style={{ height: "80%", width: "400px", background: "#fff" }}>
        {currentQuestion === -1 ? (
          <div
            className=""
            style={{
              background: "#59CD95",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",

              padding: 30,
              boxSizing: "border-box",
            }}
          >
            <div style={{ marginTop: "auto" }}>
              <div style={{ width: "100%", height: "50px" }}>
                <img
                  src="/assets/Campaigns-icon.svg"
                  style={{ marginRight: 10 }}
                  height={30}
                />
              </div>
              <div style={{ textAlign: "left", fontWeight: 600, fontSize: 30 }}>
                Квартальне опитування
              </div>
              <div
                className=""
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                  marginTop: 20,
                }}
              >
                <div>
                  <img
                    src="/assets/survey-questions.svg"
                    className="survey-icon"
                  />
                  16 questions
                </div>
                <div>
                  <img src="/assets/survey-time.svg" className="survey-icon" />7
                  mins
                </div>
                <div>
                  <img src="/assets/survey-user.svg" className="survey-icon" />
                  Anonymous
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "auto",
                marginBottom: 60,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={handleStartClick}
                style={{
                  background: "#000",
                  color: "#fff",

                  padding: "20px 80px",
                  cursor: "pointer",
                }}
              >
                Почати опитування
              </button>
            </div>
          </div>
        ) : (
          displayQuestion()
          // Відображення питань та варіантів відповідей
        )}
      </div>*/}
    </div>
  );
};

/* {selectedOption !== null && (
              // Відображення кнопки "продовжити" після вибору відповіді
              <button onClick={handleContinueClick}>Продовжити</button>
            )}*/
