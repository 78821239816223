import { ScaleChart } from "../components";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../../../utils";
import { Sidebar } from "../../Sidebar";

export const ReportData = () => {
  const navigate = useNavigate();
  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        <span style={{ fontSize: 18, fontWeight: 600 }}>
          {/*&lt;*/} Engagement Campaign 01 Report
        </span>
        <Tabs
          data={{
            Report: "/campaign/report",
            Data: "/campaign/data",
            Employees: "/campaign/employees",
          }}
          active="Data"
        />
        <div className="data" style={{ marginTop: 30 }}>
          <div className="question">
            1. How would you rate your work environment?
            <div style={{ marginTop: 10, marginLeft: 15 }}>
              Avg. Score: <span className="bold">6.2</span>
            </div>
            <div style={{ marginTop: 10, marginLeft: 15 }}>
              Median Score: <span className="bold">4</span>
            </div>
            <div style={{ maxWidth: 700, marginTop: 30 }}>
              <ScaleChart value={20} />
            </div>
          </div>
          <div className="question" style={{ marginTop: 30 }}>
            2. How would you rate your work environment?
            <div style={{ marginTop: 10, marginLeft: 15 }}>
              Avg. Score: <span className="bold">6.2</span>
            </div>
            <div style={{ marginTop: 10, marginLeft: 15 }}>
              Median Score: <span className="bold">4</span>
            </div>
            <div style={{ maxWidth: 700, marginTop: 30 }}>
              <ScaleChart value={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
