import { gql } from "@apollo/client";

export const REQUEST_CODE = gql`
  mutation requestCode($email: String!) {
    requestCode(email: $email)
  }
`;

export const REQUEST_TOKEN = gql`
  mutation requestToken($email: String!, $code: String!) {
    requestToken(email: $email, code: $code) {
      _id
      name
      email
      org
      whisper
    }
  }
`;
