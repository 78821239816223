import "./index.css";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../../utils";
import { Sidebar } from "../Sidebar";
import { useState } from "react";
import { PrimaryButton } from "../../utils";

import { GET_WHISPER, GET_ASSISTANT } from "../../lib";
import TimeAgo from "../../utils/TimeAgo";

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import {
  getWhisper as getWhisperData,
  getWhisperVariables,
} from "../../lib/graphql/query/Whisper/__generated__/getWhisper";

import {
  getAssistant as getAssistantData,
  getAssistantVariables,
} from "../../lib/graphql/query/Ai/__generated__/getAssistant";

const ai_messages = [
  "Investigate the performance of manager Victor to verify feedback.",
  "Establish clearer responsibility and task allocation system.",
  "Develop process for conflict resolution and promoting teamwork.",
];

export const WhisperInbox = ({ viewer }: any) => {
  const [updateDate, setUpdateDate] = useState<string>("");
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const { data, loading, error, refetch } = useQuery<
    getWhisperData,
    getWhisperVariables
  >(GET_WHISPER, {
    variables: { whisperId: viewer.whisper },
    skip: viewer.whisper === null,
    onError: () => {
      console.log("😐 Oh... Reload the page and try again");
    },
    onCompleted: (data) => {
      console.log(data);
      setUpdateDate(new Date().toString());
    },
  });
  console.log("rerender");
  const [fetchAssistant, { data: dataAssistant, refetch: refetchAssistant }] =
    useLazyQuery<getAssistantData, getAssistantVariables>(GET_ASSISTANT, {
      nextFetchPolicy: "standby",

      onCompleted: (data) => {
        console.log(data);
        console.log(dataAssistant);
        setSelectedMessage({
          ...selectedMessage,
          takeways: data.getAssistant,
        });

        refetch();
      },
    });

  const refreshQuery = async () => {
    await refetch();
    setUpdateDate(new Date().toString());
  };

  const LightBulb = () => {
    return (
      <div style={{ marginTop: -2 }}>
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1572 18.7178V18.6108C10.1572 17.8158 9.66123 17.1228 9.04023 16.6268C8.22969 15.9804 7.64012 15.098 7.35313 14.1018C7.06613 13.1056 7.0959 12.0448 7.43832 11.0662C7.78074 10.0877 8.41886 9.23977 9.26438 8.63984C10.1099 8.03992 11.121 7.71766 12.1577 7.71766C13.1945 7.71766 14.2056 8.03992 15.0511 8.63984C15.8966 9.23977 16.5347 10.0877 16.8771 11.0662C17.2195 12.0448 17.2493 13.1056 16.9623 14.1018C16.6753 15.098 16.0858 15.9804 15.2752 16.6268C14.6532 17.1238 14.1572 17.8158 14.1572 18.6108V18.7178M10.1572 18.7178V20.7178C10.1572 20.983 10.2626 21.2373 10.4501 21.4249C10.6377 21.6124 10.892 21.7178 11.1572 21.7178H13.1572C13.4224 21.7178 13.6768 21.6124 13.8643 21.4249C14.0519 21.2373 14.1572 20.983 14.1572 20.7178V18.7178M10.1572 18.7178H14.1572M20.1572 12.7178H21.1572M4.15723 12.7178H3.15723M12.1572 4.71777V3.71777M17.8142 7.06077L18.5212 6.35377M6.50123 7.06077L5.79323 6.35377M12.1572 15.7178V13.7178"
            stroke="#248D75"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    );
  };

  const navigate = useNavigate();

  const printMessages = () => {
    const messages: any = [];
    if (data && data?.getWhisper?.messages?.length !== undefined) {
      console.log(data?.getWhisper?.messages);
      console.log(data?.getWhisper?.messages?.length);
      data!.getWhisper!.messages!.forEach((message: any) => {
        messages.push(
          <div
            className="whisper-inbox__messages-message"
            onClick={() => setSelectedMessage(message)}
            style={{
              background: "#fff",
              ...(message.status === "new" && { background: "#EAF0F8" }),

              ...(message === selectedMessage && {
                background: "#F8FBFF",
                borderLeft: "3px solid #1C99FE",
                boxSizing: "border-box",
                paddingLeft: 27,
              }),
            }}
          >
            <div style={{ display: "flex" }}>
              {message.status === "new" ? (
                <div
                  style={{
                    height: 7,
                    width: 7,
                    background: "red",
                    borderRadius: 100,
                    marginLeft: -10,
                    marginTop: 6,
                    marginRight: 3,
                  }}
                ></div>
              ) : null}
              {message.status !== "new" ? (
                <div
                  style={{
                    height: 7,
                    width: 7,
                    background: "#F1F5F9",
                    borderRadius: 100,
                    marginLeft: -15,
                    marginTop: 6,
                    marginRight: 8,
                  }}
                ></div>
              ) : null}

              {
                <div
                  style={
                    message.status === "new"
                      ? { fontWeight: 600 }
                      : { fontWeight: 500 }
                  }
                >
                  {new Date(message.date.toString()).toLocaleDateString(
                    "en-US",
                    {
                      month: "long",
                      day: "2-digit",
                    }
                  )}
                </div>
              }
            </div>
            <div
              className="whisper-inbox__messages-message-text"
              style={{
                marginTop: 10,
                ...(message.status === "new" && {
                  fontWeight: 600,
                }),
              }}
            >
              {message.message}
            </div>
          </div>
        );
      });
      return messages;
    }
  };

  const showTakeaway = () => {
    return (
      <>
        {selectedMessage && !selectedMessage?.takeways && (
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <button
              className="green-button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() =>
                fetchAssistant({
                  variables: {
                    message: selectedMessage?.message,
                    messageId: selectedMessage?._id,
                    whisperId: viewer.whisper,
                  },
                })
              }
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 5 }}
              >
                <path
                  d="M5.71162 11.985C9.61162 11.985 11.3116 10.3442 11.3116 6.38496C11.3116 10.3442 12.9996 11.985 16.9116 11.985C12.9996 11.985 11.3116 13.673 11.3116 17.585C11.3116 13.673 9.61162 11.985 5.71162 11.985ZM0.911621 5.18496C3.41882 5.18496 4.51162 4.13056 4.51162 1.58496C4.51162 4.13056 5.59722 5.18496 8.11162 5.18496C5.59722 5.18496 4.51162 6.27056 4.51162 8.78496C4.51162 6.27056 3.41882 5.18496 0.911621 5.18496Z"
                  stroke="white"
                  stroke-width="1.25287"
                  stroke-linejoin="round"
                />
              </svg>
              AI Takeaways
            </button>
          </div>
        )}
        {selectedMessage?.takeways && (
          <div
            className="whisper-inbox__open-message-assistant"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              marginTop: 20,
            }}
          >
            {selectedMessage.takeways[0] === "N/A" ? (
              <div style={{ margin: "auto" }}>No takeaways for this mesage</div>
            ) : null}

            {selectedMessage.takeways[0] !== "N/A" && (
              <div style={{ fontSize: 15, fontWeight: 600 }}>
                Rowo AI Assistant Takeways:
              </div>
            )}

            {selectedMessage.takeways[0] !== "N/A" &&
              selectedMessage?.takeways.map((hey: any) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      lineHeight: "1.7em",
                      gap: 20,
                      fontWeight: 500,
                    }}
                  >
                    <LightBulb />
                    <div>{hey}</div>
                  </div>
                );
              })}
          </div>
        )}
      </>
    );
  };

  const MobileMessage = () => {
    return (
      <div
        className="mobile-open-message-container"
        style={
          selectedMessage
            ? {
                display: "block",
                position: "absolute",
              }
            : { display: "none" }
        }
      >
        <div className="mobile-open-message">
          <div onClick={() => setSelectedMessage(null)}>Close</div>
          <div
            style={{
              fontWeight: 500,
              color: "#BFBFBF",
              fontSize: 15,
              marginTop: 10,
            }}
          >
            {selectedMessage && selectedMessage.date
              ? new Date(selectedMessage.date.toString()).toLocaleDateString(
                  "en-US",
                  {
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              : null}
          </div>
          <div style={{ marginTop: 30 }}></div>
          {selectedMessage ? selectedMessage.message : null}
          <div style={{ marginBottom: 20 }}>{showTakeaway()}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="app-container">
      <Sidebar />
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <div
          className="header-whispe-inbox"
          style={{ boxSizing: "border-box", paddingBottom: 0, flexGrow: 1 }}
        >
          <span style={{ fontSize: 21, fontWeight: 500 }}>
            {/*&lt;*/} Whisper
          </span>
          <Tabs
            data={{
              Inbox: "/whisper/inbox",
              Settings: "/whisper/settings",
            }}
            active="Inbox"
          />
        </div>

        <MobileMessage />

        <div className="whisper-inbox__update-status">
          <TimeAgo date={updateDate} />
          {/*new Date(updateDate.toString()).toLocaleDateString("en-US", {
            month: "long",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })*/}
          <span
            style={{
              marginLeft: 10,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => refreshQuery()}
          >
            Refresh
          </span>
        </div>
        <div className="whisper-inbox">
          <div className="whisper-inbox__messages">
            {data ? printMessages() : null}

            <PrimaryButton text={"Load more"} />
          </div>
          <div
            className="whisper-inbox__open-message"
            style={{ overflow: "scroll" }}
          >
            <div
              style={{
                fontWeight: 500,
                color: "#BFBFBF",
                fontSize: 15,
                marginTop: 10,
              }}
            >
              {selectedMessage && selectedMessage.date
                ? new Date(selectedMessage.date.toString()).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }
                  )
                : null}
            </div>
            <div
              style={{
                maxWidth: 700,
                whiteSpace: "pre-wrap",
                marginTop: 20,
                fontSize: 15,
              }}
            >
              {selectedMessage && selectedMessage.message
                ? selectedMessage.message
                : null}
            </div>
            {showTakeaway()}
          </div>
        </div>
      </div>
    </div>
  );
};
