import React, { useState, useEffect } from "react";

interface TimeAgoProps {
  date: string | Date;
}

const TimeAgo: React.FC<TimeAgoProps> = ({ date }) => {
  const [timeAgo, setTimeAgo] = useState<string>("");

  useEffect(() => {
    const updateAgo = () => {
      const now = new Date();
      const pastDate = new Date(date);

      if (isNaN(pastDate.getTime())) {
        setTimeAgo("Invalid date");
        return;
      }

      const secondsAgo = Math.floor(
        (now.getTime() - pastDate.getTime()) / 1000
      );

      let interval: number;
      let formattedTimeAgo: string;

      if (secondsAgo < 60) {
        formattedTimeAgo = ">1 min ago";
        interval = 60000; // 1 хвилина
      } else if (secondsAgo < 3600) {
        const minutesAgo = Math.floor(secondsAgo / 60);
        formattedTimeAgo = `${minutesAgo} min ago`;
        interval = 60000; // 1 хвилина
      } else {
        const hoursAgo = Math.floor(secondsAgo / 3600);
        formattedTimeAgo = `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
        interval = 3600000; // 1 година
      }

      setTimeAgo(formattedTimeAgo);

      // Повторне оновлення через необхідний інтервал
      const timeoutId = setTimeout(updateAgo, interval);

      return () => clearTimeout(timeoutId);
    };

    const timeoutId = updateAgo();
    return timeoutId; // первинне виклик функції

    // очищення таймауту при демонтованні компонента
  }, [date]);

  return <span>{timeAgo}</span>;
};

export default TimeAgo;
