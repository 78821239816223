import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  Report,
  ReportData,
  Campaigns,
  Survey,
  Landing,
  LogIn,
  Whisper,
  WhisperInbox,
  WhisperSettings,
  OnboardingSurvey,
} from "./sections";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useMutation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { AUTHORIZE } from "./lib/graphql/query/";
import { authorize as authorizeData } from "./lib/graphql/query/User/__generated__/authorize";

import { useQuery } from "@apollo/client";

const defaultViewer = {
  _id: null,
  name: null,
  email: null,
  org: null,
  whisper: null,
  whisperCode: null,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const link = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
  credentials: "include",
});

const httpLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink.concat(link),
});

const App = () => {
  const [viewer, setViewer] = React.useState(defaultViewer);
  const navigate = useNavigate();
  const location = useLocation();
  const { data, loading, error } = useQuery<authorizeData>(AUTHORIZE, {
    onError: () => {
      console.log("😐 Oh... Reload the page and try again");
      /* navigate("/login");*/
    },
    onCompleted: (data: any) => {
      setViewer(data.authorize);
      if (data.authorize._id === null && location.pathname !== "/") {
        navigate("/login");
      } else {
      }
    },
  });

  React.useEffect(() => {
    console.log(viewer);
  }, [viewer]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "#F1F5F9",
        }}
      >
        <div className="logospin-loading" style={{ width: 50, height: 50 }}>
          <svg fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 0C11.215 0 0 11.215 0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.785 0 25 0ZM32.5 32.5L12.5 37.5L17.5 17.5L37.5 12.5L32.5 32.5Z"
              fill="black"
            />
            <path
              d="M25 30C27.7614 30 30 27.7614 30 25C30 22.2386 27.7614 20 25 20C22.2386 20 20 22.2386 20 25C20 27.7614 22.2386 30 25 30Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
    );
  }

  if (data && data.authorize && data.authorize._id !== null) {
  }

  return (
    <div className="app-container">
      <Routes>
        <Route path="/w/:id" element={<Whisper />} />
        <Route path="/campaign/report" element={<Report />} />
        <Route path="/campaign/data" element={<ReportData />} />
        <Route path="/campaigns" element={<Campaigns />} />
        <Route
          path="/whisper/inbox"
          element={<WhisperInbox viewer={viewer} />}
        />
        <Route
          path="/whisper/settings"
          element={<WhisperSettings viewer={viewer} />}
        />
        <Route path="/s/:id" element={<Survey />} />

        <Route
          path="/login"
          element={<LogIn viewer={viewer} setViewer={setViewer} />}
        />
        <Route path="/get-started" element={<OnboardingSurvey />} />

        <Route path="/" element={<Landing />} />
      </Routes>
    </div>
  );
};

root.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>
);
