import "./index.css";
import { PrimaryButton, BlackButton } from "../../utils";
import image from "./assets/rowo-early-access-ticket.png";
import { useNavigate } from "react-router-dom";

export const Landing = () => {
  const navigate = useNavigate();
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "auto",
          flexDirection: "column",
          color: "#fff",
        }}
      >
        <div className="landing_header" style={{ fontSize: 16, color: "#000" }}>
          <img src="/assets/rowo-logo.svg" style={{ height: 25 }} />

          {/*<div style={{ marginLeft: "auto" }}>WHISPER</div>
          <div>ENGAGE</div>*/}
          <div
            className="landing-login-button"
            style={{
              marginLeft: "auto",
              /* marginRight: "auto" */
            }}
            onClick={() => navigate("/login")}
          >
            LOGIN
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeuDH5EdkdzUzXr-5CsgNKKNb-rmoe_8jrQEmTRT8NyjhUu4A/viewform?usp=sf_link"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className="landing-button landing-getstarted"
              style={{ marginRight: 0 }}
            >
              GET STARTED
            </button>
          </a>
          <button
            className="landing-button landing-login-button-black"
            style={{ marginLeft: "auto" }}
            onClick={() => navigate("/login")}
          >
            LOGIN
          </button>
        </div>
        <div className="main" style={{ position: "relative" }}>
          <div
            className="main__shape"
            style={{
              position: "absolute",
              overflow: "hidden",
              zIndex: 0,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: "0 auto",
              borderRadius: 30,
            }}
          >
            <div
              className="main__shape-image"
              style={{
                height: 300,
                width: 300,
                position: "absolute",
                zIndex: 0,
                left: -100,
                top: 50,
                bottom: 0,
                margin: "0 auto",
              }}
            >
              <img src="/assets/shape.png" height={300} />
            </div>
            <img
              src="/assets/shape.png"
              className="main__shape-image"
              style={{
                position: "absolute",
                zIndex: 0,

                right: -30,
                top: -100,
                bottom: 0,
                margin: "0 auto",
              }}
              height={300}
            />
            <img
              src="/assets/shape.png"
              className="main__shape-image-backwards"
              style={{
                position: "absolute",
                zIndex: 0,
                left: "35%",
                right: "60%",
                bottom: -100,
              }}
              height={250}
            />
          </div>
          <div className="main__text-container" style={{ zIndex: 1 }}>
            <div></div>
            <div className="main-title">
              Engaged people deliver{" "}
              <span style={{ fontWeight: 500 }}>better</span>
            </div>
            <div style={{ fontSize: 21 }}>
              Engagement platform build specifically for IT-businesses
            </div>
          </div>
          <div className="main__image-container">
            {/*<div className="main__big-purple-thing"></div>*/}
            <img
              src="/assets/test-rowo-image.png"
              style={{
                position: "absolute",
                height: 500,
                marginTop: 100,
                overflow: "visible",
              }}
            />
          </div>
        </div>
      </div>

      <div className="video-container">
        <div
          style={{
            fontSize: 18,
            fontWeight: 500,
            padding: "0 40px",
            textAlign: "center",
          }}
        >
          Meet ROWO in 40 seconds:
        </div>
        <iframe
          src="https://player.vimeo.com/video/953746993?autoplay=1&loop=1&autopause=0&portrait=0&byline=0&color=#248D75&title=0"
          allow=" fullscreen"
          allowFullScreen
          style={{ border: "none" }}
          className="vimeo-frame"
        ></iframe>
      </div>

      <div className="early-access">
        <img src={image} className="early-access__image" />

        <div style={{ fontSize: 21, fontWeight: 600 }}>ROWO Early Access</div>
        <div className="early-access__description">
          Engagement surveys, regular reports, deep dive analytics, valuable
          insights, and strategy development
        </div>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeuDH5EdkdzUzXr-5CsgNKKNb-rmoe_8jrQEmTRT8NyjhUu4A/viewform?usp=sf_link"
          target="_blank"
          rel="noreferrer"
        >
          <BlackButton text={"GET STARTED"} />
        </a>
      </div>

      {/* <div className="whisper">
        <div className="whisper-title">ROWO Whisper</div>
        <div className="whisper-title__slogan">Make Your Team Feel Heard</div>
        <div className="whisper-title__description" style={{ marginTop: -10 }}>
          {" "}
          Simple tool for employee anonymous feedback.
        </div>

        <div className="whisper-usecases">
          <div className="whisper-usecases__item">
            <div className="whisper-usecases__item-title">
              Valuable Insights
            </div>
            <div className="whisper-usecases__item-description">
              Use employee feedback to strengthen your decisions and address
              their problems and concerns
            </div>
          </div>
          <div className="whisper-usecases__item">
            <div className="whisper-usecases__item-title">Suggestion Box</div>
            <div className="whisper-usecases__item-description">
              Effortlessly collect ideas from your team members
            </div>
          </div>

          <div className="whisper-usecases__item">
            <div className="whisper-usecases__item-title">Q&A Sessions</div>
            <div className="whisper-usecases__item-description">
              Periodic Q&A sessions with the team help build trust and
              confidence among employees
            </div>
          </div>
        </div>
        <div className="whisper-buttons">
          <button
            className="landing-button"
            style={{ color: "#fff", background: "#248D75" }}
          >
            DEMO
          </button>
          <button className="landing-button">TRY WHISPER</button>
        </div>
      </div>
      <div className="rowo-engage">
        <div className="rowo-logo rowospin">
          <svg
            width="30"
            height="30"
            viewBox="0 0 150 150"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75 0C33.645 0 0 33.645 0 75C0 116.355 33.645 150 75 150C116.355 150 150 116.355 150 75C150 33.645 116.355 0 75 0ZM97.5 97.5L37.5 112.5L52.5 52.5L112.5 37.5L97.5 97.5Z"
              fill="black"
            />
            <path
              d="M75 90C83.2843 90 90 83.2843 90 75C90 66.7157 83.2843 60 75 60C66.7157 60 60 66.7157 60 75C60 83.2843 66.7157 90 75 90Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="rowo-enagage__title">ROWO Engage</div>
        <div className="rowo-enagage__description">
          Engagement surveys, regular reports, deep dive analytics, valuable
          insights, strategy development and more. Coming soon.
        </div>

        <button className="rowo-engage__notify-button">Notify me</button>
      </div>
      <div className="pricing">
        <div className="pricing-title">PRICING</div>
        <div className="pricing-price">$0.00</div>
        <div className="pricing-text" style={{ marginTop: 20 }}>
          While ROWO is in beta,{" "}
          <span style={{ fontWeight: 600 }}>
            all current features are free.
          </span>
        </div>
        <div
          className="pricing-text"
          style={{ marginTop: 20, maxWidth: 500, lineHeight: 1.8 }}
        >
          Start using ROWO now and secure that{" "}
          <span style={{ fontWeight: 600 }}>
            those features remain free for you forever.
          </span>
        </div>
        <div>
          <button
            className="landing-button"
            style={{ marginLeft: 0, marginTop: 40 }}
          >
            GET STARTED
          </button>
        </div>
      </div>*/}
      <div className="footer">
        <div className="footer-content" style={{ position: "relative" }}>
          <div
            className="footer-block"
            style={{
              color: "#fff",
              fontSize: 14,
              fontWeight: 300,
              opacity: 0.8,
            }}
          >
            From 🇺🇦 with ❤️ <br />
            Thanks for the support! <br />
            <br />
            <span style={{ fontWeight: 500 }}>ROWO, 2024</span>
          </div>
          <div
            className="footer-block"
            style={{
              color: "#fff",
              fontSize: 14,
              fontWeight: 300,
              opacity: 0.8,
              textAlign: "right",
            }}
          >
            Contact, Support, Ideas <br /> & Suggestions, Special Inquiries
            <br />
            <br />
            <span style={{ fontWeight: 500 }}>contact@rowo.app</span>
          </div>
        </div>
      </div>

      {/*} <div
        className="logo"
        style={{
          fontSize: 28,
          fontWeight: 600,
          position: "absolute",
          top: 50,
        }}
      >
        <img src="/assets/ROWO.svg" style={{ width: 80 }} />
    </div> */}
      {/* <div className="logo" style={{ position: "absolute", top: 20, left: 30 }}>
        <img src="/assets/Rovo-logo.svg" alt="Rovo service logo"></img>
      </div>

      <div>
        <img
          className="logospin"
          style={{ marginRight: 10, width: 35, height: 40 }}
          src="/assets/logo.svg"
        />
        Engaged teams deliver <span style={{ fontWeight: 500 }}>better</span>
      </div>
      <div style={{ marginTop: 20, fontSize: 28, fontWeight: 400 }}>
        Engagement tool built specifically for IT-businesses
  </div> */}
    </div>
  );
};
