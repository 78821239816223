import { useState } from "react";

export const SingleChoice = ({ setSelectedOption, options, question }: any) => {
  const [selectedValue, setSelectedValue] = useState<number | null>();

  const handleScaleClick = (variant: any) => {
    setSelectedValue(variant === selectedValue ? null : variant);
    setSelectedOption(variant === selectedValue ? null : variant);
    console.log(selectedValue);
  };
  return (
    <div className="single-choice" style={{ flexGrow: 1, marginTop: 20 }}>
      {options.map((variant: any) => {
        return (
          <div
            /* className={` ${
              selectedValue === variant ? "selected" : ""
            }`} */
            onClick={() => setSelectedOption(variant)}
          >
            <label className="variant">
              {variant}
              <input type="radio" name={question}></input>
              <span className="checkmark"></span>
            </label>
          </div>
        );
      })}
    </div>
  );
};
