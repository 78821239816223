import { useNavigate } from "react-router-dom";

export const Tabs = ({ data, active }: any) => {
  const navigate = useNavigate();

  const printTabs = () => {
    const elements = [];
    for (const tab in data) {
      elements.push(
        <div
          style={{
            background: active === tab ? "#fff" : "#F1F5F9",
            padding: "5px 10px",
            paddingLeft: 12,
            fontSize: 14,
            fontWeight: 500,
            borderRadius: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate(data[tab as keyof typeof data])}
        >
          {tab}
        </div>
      );
    }
    return elements;
  };

  return (
    <div
      style={{
        background: "#F1F5F9",
        padding: 5,
        display: "flex",
        flexDirection: "row",
        width: "fit-content",
        borderRadius: 5,
        marginTop: 20,
      }}
    >
      {printTabs()}
    </div>
  );
};
