import { useState, useEffect } from "react";
import "./index.css";
import { SingleChoice, Scale, OpenQuestion } from "./components";

import { GET_SURVEY, SEND_RESPONSE, AUTHORIZE } from "../../lib";

import { useQuery, useMutation } from "@apollo/client";
import { authorize as authorizeData } from "../../lib/graphql/query/User/__generated__/authorize";

import {
  getSurvey as getSurveyData,
  getSurveyVariables,
} from "../../lib/graphql/query/Survey/__generated__/getSurvey";

import {
  sendResponse as sendResponseData,
  sendResponseVariables,
} from "../../lib/graphql/query/Survey/__generated__/sendResponse";

import { useParams } from "react-router-dom";

const data_sample = [
  {
    id: 1,
    question: "Компанія вкладає достатньо в розвиток своїх співробітників?",
    type: "scale",
    options: ["JavaScript", "Python", "Java", "C#"],
  },
  {
    id: 3,
    question: "Чи зрозумілі вам рішення, що приймаються керівництвом?",
    type: "singlechoice",
    options: ["Цілком зрозумілі й логічні", "Не завжди", "Ні"],
  },
  {
    id: 2,
    question: "Ви любите програмувати?",
    type: "singlechoice",
    options: ["Ні", "Зовсім не люблю", "Дуже не люблю"],
  },
  {
    id: 4,
    question: "Яка ваша улюблена мова програмування?",
    type: "open",
    options: ["JavaScript", "Python", "Java", "C#"],
  },
  {
    id: 5,
    question: "Яка ваша улюблена мова програмування?",
    type: "scale",
    options: ["JavaScript", "Python", "Java", "C#"],
  },
];

interface QuestionResponse {
  question: string;
  answer: string;
}

const StartScreen = ({ name, surveyLength, startSurvey }: any) => {
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#59CD95");
  }, []);
  return (
    <div
      className=""
      style={{
        background: "#59CD95",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 30,
        boxSizing: "border-box",
      }}
    >
      <div style={{ marginTop: "auto" }}>
        <div style={{ width: "100%", height: "50px" }}>
          <img
            src="/assets/Campaigns-icon.svg"
            style={{ marginRight: 10 }}
            height={30}
          />
        </div>
        <div style={{ textAlign: "left", fontWeight: 600, fontSize: 30 }}>
          {name}
        </div>
        <div
          className=""
          style={{
            textAlign: "left",
            fontSize: 16,
            display: "flex",
            flexDirection: "column",
            gap: 15,
            marginTop: 20,
          }}
        >
          <div>
            <img src="/assets/survey-questions.svg" className="survey-icon" />
            {surveyLength} questions
          </div>
          <div>
            <img src="/assets/survey-time.svg" className="survey-icon" />7 mins
          </div>
          <div>
            <img src="/assets/survey-user.svg" className="survey-icon" />
            Anonymous
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "auto",
          marginBottom: 60,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button
          onClick={() => startSurvey()}
          style={{
            background: "#000",
            color: "#fff",

            padding: "20px 80px",
            cursor: "pointer",
          }}
        >
          Почати опитування
        </button>
      </div>
    </div>
  );
};

const FinalScreen = ({ startAgain }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      Дякую, Survey completed!
      <button
        onClick={startAgain}
        style={{
          background: "#000",
          color: "#fff",
          padding: "20px 80px",
          cursor: "pointer",
        }}
      >
        Почати знову
      </button>
    </div>
  );
};

const Question = ({
  question,
  nextQuestion,
  surveyLength,
  currentQuestion,
  responses,
}: any) => {
  const { _id: id, name, type, options } = question;
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#fff");
  }, []);
  return (
    <div
      key={id}
      className="fadein-up fadeInUp-animation-survey"
      style={{
        padding: 30,
        position: "relative",
        height: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        background: "none",
      }}
    >
      <div style={{ marginTop: 20, fontWeight: 700, fontSize: 14 }}>
        {currentQuestion + 1}/{surveyLength}
      </div>

      <div
        style={{
          marginTop: 20,
          fontSize: 18,
          lineHeight: 1.5,
          marginBottom: 20,
        }}
      >
        {name}
      </div>
      {type === "scale" ? (
        <Scale
          setSelectedOption={setSelectedOption}
          className="fadein-up fadeInUp-animation-survey"
        />
      ) : null}

      {type === "open" ? (
        <OpenQuestion
          setSelectedOption={setSelectedOption}
          className="fadein-up fadeInUp-animation-survey"
        />
      ) : null}

      {type === "single" ? (
        <SingleChoice
          setSelectedOption={setSelectedOption}
          options={options}
          question={name}
          className="fadein-up fadeInUp-animation-survey"
        />
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {type === "open" ? (
          <div
            style={{
              color: "#A1A1A1",
              fontSize: 18,
              fontWeight: 500,
              marginLeft: 40,
              cursor: "pointer",
            }}
          >
            Skip
          </div>
        ) : null}

        <div
          style={{
            marginTop: "auto",
            marginLeft: "auto",
            marginRight: 20,
            opacity: selectedOption !== null ? 1 : 0.5,
            cursor: "pointer",
          }}
          onClick={() => {
            if (selectedOption) {
              responses.push({ selectedOption });
              nextQuestion();
              console.log(responses);
            }
          }}
        >
          <img src="/assets/next-question.svg" style={{ height: 80 }} />
        </div>
      </div>
    </div>
  );
};

const QuestionOperator = ({ data, responses, sendResponses }: any) => {
  const [surveyLength, setSurveyLength] = useState(
    data.getSurvey.questions.length
  );
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const { name, questions } = data.getSurvey;
  console.log(name);
  console.log(surveyLength);

  useEffect(() => {
    if (currentQuestion === surveyLength) {
      return sendResponses();
    }
  }, [currentQuestion]);

  const nextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
  };
  const startAgain = () => {
    setCurrentQuestion(-1);
  };
  return (
    <div className="survey-display">
      {
        currentQuestion === -1 ? (
          <StartScreen
            name={name}
            surveyLength={surveyLength}
            startSurvey={nextQuestion}
          />
        ) : currentQuestion === surveyLength ? (
          <FinalScreen startAgain={startAgain} />
        ) : (
          <Question
            question={questions[currentQuestion]}
            nextQuestion={nextQuestion}
            surveyLength={surveyLength}
            currentQuestion={currentQuestion}
            responses={responses}
          />
        )
        // Відображення питань та варіантів відповідей
      }
    </div>
  );
};

export const Survey = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentQue, setCurrentQue] = useState<any>(
    data_sample[currentQuestion]
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [userResponses, setUserResponses]: any = useState([]);
  const [surveyCompleted, setSurveyCompleted]: any = useState(false);

  let { id: survey_id } = useParams();

  const responses: any = [];

  const { data, loading, error } = useQuery<getSurveyData, getSurveyVariables>(
    GET_SURVEY,
    {
      variables: { surveyId: survey_id! },
      onError: () => {
        console.log("😐 Oh... Reload the page and try again");
      },
      onCompleted: (data) => {
        console.log(data);
        if (data.getSurvey?.questions && data.getSurvey?.questions.length > 0) {
          setCurrentQue(data?.getSurvey?.questions[0]);
          console.log(currentQue);
        }
      },
    }
  );
  const showSurvey = () => {
    return data ? (
      <QuestionOperator
        data={data}
        responses={responses}
        sendResponses={sendResponses}
      />
    ) : null;
  };

  const [sendResponse] = useMutation<sendResponseData, sendResponseVariables>(
    SEND_RESPONSE,
    {
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    if (
      surveyCompleted === true &&
      userResponses.length === data_sample.length
    ) {
      console.log("Опитування завершено!");
      console.log("Відповіді користувача:", userResponses);
    }
  }, [surveyCompleted]);

  useEffect(() => {}, [currentQuestion]);

  const sendResponses = () => {
    sendResponse({
      variables: {
        surveyId: survey_id!,
        responses: responses,
      },
    });
  };

  return (
    <div
      style={{
        background: "#377762",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {showSurvey()}
      {/* 
      <div style={{ height: "80%", width: "400px", background: "#fff" }}>
        {currentQuestion === -1 ? (
          <div
            className=""
            style={{
              background: "#59CD95",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",

              padding: 30,
              boxSizing: "border-box",
            }}
          >
            <div style={{ marginTop: "auto" }}>
              <div style={{ width: "100%", height: "50px" }}>
                <img
                  src="/assets/Campaigns-icon.svg"
                  style={{ marginRight: 10 }}
                  height={30}
                />
              </div>
              <div style={{ textAlign: "left", fontWeight: 600, fontSize: 30 }}>
                Квартальне опитування
              </div>
              <div
                className=""
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 15,
                  marginTop: 20,
                }}
              >
                <div>
                  <img
                    src="/assets/survey-questions.svg"
                    className="survey-icon"
                  />
                  16 questions
                </div>
                <div>
                  <img src="/assets/survey-time.svg" className="survey-icon" />7
                  mins
                </div>
                <div>
                  <img src="/assets/survey-user.svg" className="survey-icon" />
                  Anonymous
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "auto",
                marginBottom: 60,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={handleStartClick}
                style={{
                  background: "#000",
                  color: "#fff",

                  padding: "20px 80px",
                  cursor: "pointer",
                }}
              >
                Почати опитування
              </button>
            </div>
          </div>
        ) : (
          displayQuestion()
          // Відображення питань та варіантів відповідей
        )}
      </div>*/}
    </div>
  );
};

/* {selectedOption !== null && (
              // Відображення кнопки "продовжити" після вибору відповіді
              <button onClick={handleContinueClick}>Продовжити</button>
            )}*/
