import { gql } from "@apollo/client";

export const GET_WHISPER = gql`
  query getWhisper($whisperId: ID) {
    getWhisper(whisperId: $whisperId) {
      _id
      org
      displayedName
      displayedPosition
      messages {
        _id
        message
        date
        seenBy
        takeways
      }
    }
  }
`;

export const GET_WHISPER_BY_LINK = gql`
  query getWhisperByLink($whisperLink: String) {
    getWhisper(whisperLink: $whisperLink) {
      _id
      org
      displayedName
      displayedPosition
    }
  }
`;

export const SEND_WHISPER_MESSAGE = gql`
  mutation sendWhisperMessage($whisperId: ID!, $message: String!) {
    sendWhisperMessage(whisperId: $whisperId, message: $message)
  }
`;

export const EDIT_WHISPER = gql`
  mutation editWhisper($whisperId: ID!, $input: JSON!) {
    editWhisper(whisperId: $whisperId, input: $input) {
      _id
      displayedName
      displayedPosition
    }
  }
`;

/*{
      _id
      org
      messages {
        _id
        message
        date
        seenBy
      }
    }*/
