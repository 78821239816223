import "./index.css";

import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { REQUEST_CODE, REQUEST_TOKEN } from "../../lib";
import OtpInput from "react-otp-input";

import {
  requestCode as requestCodeData,
  requestCodeVariables,
} from "../../lib/graphql/query/LogIn/__generated__/requestCode";
import {
  requestToken as requestTokenData,
  requestTokenVariables,
} from "../../lib/graphql/query/LogIn/__generated__/requestToken";
import { useNavigate } from "react-router-dom";

export const LogIn = ({ viewer, setViewer }: any) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [otp, setOtp] = useState("");
  const [showCode, setShowCode] = useState(false);

  const [requestCode] = useMutation<requestCodeData, requestCodeVariables>(
    REQUEST_CODE,
    {
      variables: { email: email },
      onCompleted: (data) => {
        setShowCode(true);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [requestToken] = useMutation<requestTokenData, requestTokenVariables>(
    REQUEST_TOKEN,
    {
      variables: { email: email, code: code },
      onCompleted: (data) => {
        setViewer(data.requestToken);
        console.log(viewer.requestToken);
        navigate("/whisper/inbox");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <div
      style={{
        background: "#F8F8F8",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 450,
          height: "500px",
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 20,
        }}
      >
        <div>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 0C11.215 0 0 11.215 0 25C0 38.785 11.215 50 25 50C38.785 50 50 38.785 50 25C50 11.215 38.785 0 25 0ZM32.5 32.5L12.5 37.5L17.5 17.5L37.5 12.5L32.5 32.5Z"
              fill="black"
            />
            <path
              d="M25 30C27.7614 30 30 27.7614 30 25C30 22.2386 27.7614 20 25 20C22.2386 20 20 22.2386 20 25C20 27.7614 22.2386 30 25 30Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="welcome-back">Welcome back!</div>
        <div style={{ marginTop: 0, textAlign: "center" }}>
          {!showCode && "Please enter your work email"}{" "}
          {showCode && (
            <span>
              Please enter code sent to{" "}
              <span style={{ fontWeight: 600 }}>{email}</span>{" "}
            </span>
          )}
        </div>
        {!showCode && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <input
              placeholder="E-mail"
              className="email-input"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
        )}
        {showCode && (
          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={false}
            inputStyle="inputStyle"
            inputType="number"
          />
        )}

        <div>
          {email.length === 0 && !showCode && (
            <button className="secondary-button">That's me!</button>
          )}
          {email.length > 0 && !showCode && (
            <button className="primary-button" onClick={() => requestCode()}>
              That's me!
            </button>
          )}
          {code.length < 6 && showCode && (
            <button className="secondary-button">Submit</button>
          )}
          {code.length === 6 && showCode && (
            <button className="primary-button" onClick={() => requestToken()}>
              Submit
            </button>
          )}
          {/*
          <button className="primary-button" onClick={() => requestToken()}>
            LogIn
        </button>*/}
        </div>
      </div>
    </div>
  );
};
