import { gql } from "@apollo/client";

export const AUTHORIZE = gql`
  query authorize {
    authorize {
      _id
      name
      email
      org
      whisper
      whisperCode
    }
  }
`;

export const LOG_OUT = gql`
  mutation logOut {
    logOut {
      _id
      name
      email
      org
      whisper
    }
  }
`;
