import { PieChart, Pie, Cell } from "recharts";

export const HalfPieChart = ({ value }: { value: number }) => {
  const data = [{ value: value }, { value: 100 - value }];
  return (
    <PieChart height={200} width={200}>
      <Pie
        startAngle={180}
        endAngle={0}
        innerRadius="65%"
        data={data}
        dataKey="value"
        labelLine={false}
        blendStroke
        isAnimationActive={true}
      >
        <Cell fill="#14814D" />
        <Cell fill="#eaeaea" />
      </Pie>
    </PieChart>
  );
};
