import "./index.css";
import { Sidebar } from "../Sidebar";
import { Tabs } from "../../utils";
import { PrimaryButton, BlackButton } from "../../utils";
import { Pdf } from "./components/pdf";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";

import { useQuery, useMutation } from "@apollo/client";

import { GET_WHISPER, EDIT_WHISPER } from "../../lib";

import {
  getWhisper as getWhisperData,
  getWhisperVariables,
} from "../../lib/graphql/query/Whisper/__generated__/getWhisper";

import {
  editWhisper as editWhisperData,
  editWhisperVariables,
} from "../../lib/graphql/query/Whisper/__generated__/editWhisper";
import { useState, useEffect } from "react";

export const WhisperSettings = ({ viewer }: any) => {
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const { data, loading, error } = useQuery<
    getWhisperData,
    getWhisperVariables
  >(GET_WHISPER, {
    variables: { whisperId: viewer.whisper },
    onError: () => {
      console.log("😐 Oh... Reload the page and try again");
    },
    onCompleted: (data) => {},
  });
  const [editWhisper] = useMutation<editWhisperData, editWhisperVariables>(
    EDIT_WHISPER,
    {
      onCompleted: (data) => {
        console.log(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const updateWhisper = () => {
    editWhisper({
      variables: {
        whisperId: viewer.whisper,
        input: {
          displayedName: name,
          displayedPosition: position,
        },
      },
    });
  };
  useEffect(() => {
    console.log(data);
    if (data && data.getWhisper?.displayedName) {
      setName(data.getWhisper.displayedName);
      console.log(name);
    }
  }, [data]);

  return (
    <div className="app-container">
      <Sidebar />
      <div className="content">
        <span style={{ fontSize: 21, fontWeight: 500 }}>
          {/*&lt;*/} Whisper
        </span>
        <Tabs
          data={{
            Inbox: "/whisper/inbox",
            Settings: "/whisper/settings",
          }}
          active="Settings"
        />

        <div className="linkholder" style={{}}>
          <div>Share this link</div>
          <div
            style={{
              marginTop: 10,
            }}
          >
            <div
              className="whisper-inbox__link-container"
              style={{
                background: "#fff",
                padding: "10px 30px",
                borderRadius: 7,
                display: "inline-block",
                boxSizing: "border-box",
                margin: 10,
                marginLeft: 0,
                fontSize: 14,
              }}
            >
              <a
                href={`https://rowo.app/w/${viewer.whisperCode}`}
                target="_blank"
                rel="noreferrer"
              >
                rowo.app/w/{viewer.whisperCode}
              </a>
            </div>
            <BlobProvider document={<Pdf url="4h18xjp" />}>
              {({ blob, url, loading, error }) => (
                <a href={url!} target="_blank" rel="noreferrer">
                  <PrimaryButton text="Get Printable PDF" />
                </a>
              )}
            </BlobProvider>
          </div>
        </div>
        <div style={{ maxWidth: 300 }}>
          <div style={{ marginTop: 20, fontSize: 14 }}>Displayed Name</div>
          <input
            className="input"
            placeholder="John Smith"
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></input>
          <div style={{ marginTop: 20, fontSize: 14 }}>Displayed Position</div>
          <input
            className="input"
            placeholder="Keep it short"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          ></input>
        </div>
        <div style={{ marginTop: 30 }}>
          <span onClick={() => updateWhisper()}>
            <BlackButton text="Save" style={{ marginTop: 20 }} />
          </span>

          {/*<a
            href="http://localhost:3000/w/klm3a"
            target="_blank"
            rel="noreferrer"
          >
            <BlackButton text="Preview" style={{ marginTop: 20 }} />
            </a>*/}
        </div>

        <div style={{ marginTop: 40, fontSize: 14, color: "#86909A" }}>
          Custom colors, senders allowlists and custom texts are coming soon.
        </div>
      </div>
    </div>
  );
};
