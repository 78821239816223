import { PieChart, Pie, Cell } from "recharts";

export const CircleChart = ({ value }: { value: number }) => {
  const data = [{ value: value }, { value: 100 - value }];
  return (
    <PieChart height={115} width={115}>
      <Pie
        innerRadius="66%"
        data={data}
        dataKey="value"
        labelLine={false}
        blendStroke
        isAnimationActive={true}
        focusable={false}
        startAngle={-270}
      >
        <Cell
          fill={value > 80 ? "#14814D" : value > 30 ? "#F5A623" : "#EE4E4E"}
          style={{ outline: "none" }}
        />
        <Cell fill="#eaeaea" style={{ outline: "none" }} />
      </Pie>
    </PieChart>
  );
};
