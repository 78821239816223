import { useState, useEffect } from "react";

export const OpenQuestion = ({ setSelectedOption }: any) => {
  const [selectedValue, setSelectedValue] = useState<string>("");
  useEffect(() => {
    setSelectedOption(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setSelectedOption(null);
  }, []);

  const handleScaleClick = (value: any) => {
    setSelectedValue(value === selectedValue ? null : value);
    setSelectedOption(value === selectedValue ? null : value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginBottom: 30,
      }}
    >
      <textarea
        className="open-question-input"
        maxLength={2200}
        placeholder="Type here..."
        style={{ flexGrow: 1 }}
        value={selectedValue}
        onChange={(e) => setSelectedValue(e.target.value)}
      />
    </div>
  );
};
