import { gql } from "@apollo/client";

export const GET_SURVEY = gql`
  query getSurvey($surveyId: ID!) {
    getSurvey(surveyId: $surveyId) {
      _id
      name
      owner
      questions {
        _id
        category
        name
        options
        type
      }
      status
    }
  }
`;

export const SEND_RESPONSE = gql`
  mutation sendResponse($surveyId: ID!, $responses: [JSON]) {
    sendResponse(surveyId: $surveyId, responses: $responses) {
      _id
      name
      owner
      questions {
        _id
        category
        name
        options
        type
      }
      status
    }
  }
`;
