import { useState, useEffect } from "react";

export const Scale = ({ setSelectedOption }: any) => {
  const [selectedValue, setSelectedValue] = useState<number>(0);

  useEffect(() => {
    setSelectedOption(null);
  }, []);

  const handleScaleClick = (value: number) => {
    setSelectedValue(value === selectedValue ? 0 : value);
    setSelectedOption(value === selectedValue ? null : value);
  };
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="scale-container">
        {Array.from({ length: 10 }, (_, index) => (
          <div
            key={index + 1}
            className={`scale-item ${selectedValue > index ? "selected" : ""}`}
            onClick={() => handleScaleClick(index + 1)}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </div>
  );
};
