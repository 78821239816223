import { gql } from "@apollo/client";

export const GET_ASSISTANT = gql`
  query getAssistant($message: String!, $messageId: ID!, $whisperId: ID!) {
    getAssistant(
      message: $message
      messageId: $messageId
      whisperId: $whisperId
    )
  }
`;
