import "./index.css";
import countryList from "react-select-country-list";
import Select from "react-select";
import Creatable, { useCreatable } from "react-select/creatable";
import { useReducer } from "react";
import { PrimaryButton } from "../../utils";

const initialState = {
  firstName: "",
  lastName: "",
  company: "",
  position: "",
  country: "",
  website: "",
  numOfEmployees: "",
  error: "",
};

export const OnboardingSurvey = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const jobs = [
    { value: "Human Resources Manager", label: "HR (Human Resources)" },
    { value: "Owner", label: "Owner" },
    { value: "Chief Officer", label: "Chief Officer" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Other", label: "Other(You can type it here and click Create)" },
    { value: "fun", label: "Just curious, those answers not real" },
  ];
  const numberOfEmployees = [
    { value: "1-10", label: "1-10" },
    { value: "10-50", label: "10-50" },
    { value: "50-100", label: "50-100" },
    { value: "100-500", label: "100-500" },
    { value: "500-1000", label: "500-1000" },
    { value: "1000+", label: "1000+" },
  ];
  const countries = countryList()
    .getData()
    .filter((c) => c.value !== "RU");
  console.log(state);

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "UPDATE_VALUE":
        return { ...state, [action.field]: action.value };
      case "SET_ERROR":
        return { ...state, error: action.error };
      default:
        return state;
    }
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    dispatch({ type: "UPDATE_VALUE", field: name, value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      state.firstName &&
      state.lastName &&
      state.country &&
      state.position &&
      state.company &&
      state.numberOfEmployees
    ) {
      console.log("okay");
    } else {
      dispatch({
        type: "SET_ERROR",
        error: "Please, fill all required fields",
      });
    }
  };

  return (
    <div className="onboarding-container">
      <div className="onboarding-header__title">Just one more step</div>
      <div className="onboarding-header__description">
        Please, answer these questions to helps us understand you and your needs
        better
      </div>

      <div className="onboarding-form">
        <div className="onboarding-block">
          <div className="onboarding-form__question">
            <div
              className="onboarding-form__question-title"
              style={{
                ...(state.error &&
                  !state.firstName && {
                    color: "red",
                  }),
              }}
            >
              Fisrt Name*
            </div>
            <input
              className="onboarding-form__input"
              name="firstName"
              onChange={handleChange}
            ></input>
          </div>
          <div className="onboarding-form__question">
            <div
              className="onboarding-form__question-title"
              style={{
                ...(state.error &&
                  !state.lastName && {
                    color: "red",
                  }),
              }}
            >
              Last Name*
            </div>
            <input
              className="onboarding-form__input"
              name="lastName"
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className="onboarding-block">
          <div className="onboarding-form__question">
            <div
              className="onboarding-form__question-title"
              style={{
                ...(state.error &&
                  !state.company && {
                    color: "red",
                  }),
              }}
            >
              Company Name*
            </div>
            <input
              className="onboarding-form__input"
              name="company"
              onChange={handleChange}
            ></input>
          </div>
          <div className="onboarding-form__question">
            <div
              className="onboarding-form__question-title"
              style={{
                ...(state.error &&
                  !state.country && {
                    color: "red",
                  }),
              }}
            >
              {" "}
              Country*
            </div>
            <Select
              options={countries}
              name="country"
              onChange={(e) => {
                dispatch({
                  type: "UPDATE_VALUE",
                  field: "country",
                  value: e?.value,
                });
              }}
            />
          </div>
        </div>
        <div className="onboarding-block">
          <div className="onboarding-form__question">
            <div
              className="onboarding-form__question-title"
              style={{
                ...(state.error &&
                  !state.numberOfEmployees && {
                    color: "red",
                  }),
              }}
            >
              {" "}
              Number of employees, app.*
            </div>

            <Select
              options={numberOfEmployees}
              onChange={(e) => {
                dispatch({
                  type: "UPDATE_VALUE",
                  field: "numberOfEmployees",
                  value: e?.value,
                });
              }}
            />
          </div>
          <div className="onboarding-form__question">
            <div className="onboarding-form__question-title">
              Company website
            </div>
            <input className="onboarding-form__input"></input>
          </div>
        </div>
        <div className="onboarding-block">
          <div
            className="onboarding-form__question"
            style={{ paddingRight: 15, boxSizing: "border-box" }}
          >
            <div
              className="onboarding-form__question-title"
              style={{
                ...(state.error &&
                  !state.position && {
                    color: "red",
                  }),
              }}
            >
              Position
            </div>
            <Creatable
              options={jobs}
              onChange={(e) => {
                dispatch({
                  type: "UPDATE_VALUE",
                  field: "position",
                  value: e?.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 20 }} onClick={handleSubmit}>
        <PrimaryButton text={"Submit"} />
      </div>
    </div>
  );
};
