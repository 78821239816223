import "./index.css";
import { useNavigate } from "react-router-dom";
import { LOG_OUT } from "../../lib";
import { useMutation } from "@apollo/client";
import { logOut as logOutData } from "../../lib/graphql/query/User/__generated__/logOut";
import { useState } from "react";

const MobileSidebar = (logOut: any) => {
  const [showSidebar, setShowSidebar] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="mobile-sidebar">
      {!showSidebar && (
        <div
          style={{
            position: "absolute",
            right: 30,
            top: 30,
            fontSize: 30,
            cursor: "pointer",
          }}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          ☰
        </div>
      )}
      {showSidebar && (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <div
            style={{ flexGrow: 1, background: "#000", opacity: 0.5 }}
            onClick={() => setShowSidebar(!showSidebar)}
          ></div>
          <div
            style={{
              minWidth: 220,
              background: "#F1F5F9",
              height: "100%",
              padding: 20,
              boxSizing: "border-box",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="logo">
              <img
                src="https://rowo.app/assets/rowo-logo.svg"
                width="100px"
                height="26.25px"
                style={{ marginTop: 18 }}
                alt="Rovo service logo"
              ></img>
            </div>
            <div className="sidebar-tabs">
              <div
                className="sidebar-tab"
                onClick={() => navigate("/whisper/inbox")}
              >
                <img
                  src="/assets/Whisper-icon.svg"
                  style={{ marginRight: 15 }}
                />
                <span style={{}}>Whisper</span>
              </div>
              <div
                className="sidebar-tab"
                onClick={() => navigate("/campaigns")}
              >
                <img
                  src="/assets/Campaigns-icon.svg"
                  style={{ marginRight: 17, marginLeft: -4 }}
                />
                <span style={{}}>Engage</span>
              </div>
            </div>

            <div className="sidebar-tab" onClick={() => logOut()}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: 17 }}
              >
                <path
                  d="M7.91016 19.2449H3.91016C3.37972 19.2449 2.87102 19.0342 2.49594 18.6591C2.12087 18.284 1.91016 17.7753 1.91016 17.2449V3.24487C1.91016 2.71444 2.12087 2.20573 2.49594 1.83066C2.87102 1.45559 3.37972 1.24487 3.91016 1.24487H7.91016M14.9102 15.2449L19.9102 10.2449M19.9102 10.2449L14.9102 5.24487M19.9102 10.2449H7.91016"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span style={{ marginTop: 2 }}>Log Out</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export const Sidebar = () => {
  const navigate = useNavigate();
  const [logOut] = useMutation<logOutData>(LOG_OUT, {
    onCompleted: () => navigate("/login"),
  });

  return (
    <>
      <MobileSidebar logOut={logOut} />
      <div
        className="sidebar"
        style={{
          minWidth: 220,
          background: "#F1F5F9",
          height: "100%",
          padding: 20,
          boxSizing: "border-box",
        }}
      >
        <div className="logo">
          <img
            src="https://rowo.app/assets/rowo-logo.svg"
            width="100px"
            height="26.25px"
            style={{ marginTop: 18 }}
            alt="Rovo service logo"
          ></img>
        </div>
        <div className="sidebar-tabs">
          <div
            className="sidebar-tab"
            onClick={() => navigate("/whisper/inbox")}
          >
            <img src="/assets/Whisper-icon.svg" style={{ marginRight: 15 }} />
            <span style={{}}>Whisper</span>
          </div>
          <div className="sidebar-tab" onClick={() => navigate("/campaigns")}>
            <img
              src="/assets/Campaigns-icon.svg"
              style={{ marginRight: 17, marginLeft: -4 }}
            />
            <span style={{}}>Engage</span>
          </div>
        </div>

        <div className="sidebar-tab" onClick={() => logOut()}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginRight: 17 }}
          >
            <path
              d="M7.91016 19.2449H3.91016C3.37972 19.2449 2.87102 19.0342 2.49594 18.6591C2.12087 18.284 1.91016 17.7753 1.91016 17.2449V3.24487C1.91016 2.71444 2.12087 2.20573 2.49594 1.83066C2.87102 1.45559 3.37972 1.24487 3.91016 1.24487H7.91016M14.9102 15.2449L19.9102 10.2449M19.9102 10.2449L14.9102 5.24487M19.9102 10.2449H7.91016"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span style={{ marginTop: 2 }}>Log Out</span>
        </div>
      </div>
    </>
  );
};
